/**
 *  ContributorPage
 * @author Dan Guterman
 *
 * @copyright Klipfolio, All rights reserved
 */
import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from '@breakpoints'
import Heading from '@particles/heading'
import { HeaderContainer } from '@organisms/header'
import SEO from '@atoms/seo'
import Image from '@atoms/image'
import Icon from '@icons'
import Layout from '@organisms/layout'
import Text from '@particles/text'
import SourceHTML from '@particles/source-html'
import Card from '@atoms/card'
import background from '../images/bg-triangles.svg'
import Social from '@atoms/social'
import Tags from '@molecules/tags'
import ServiceLogo from '@atoms/service-logo'
import Link from '@atoms/link'
import { isExpertContributed } from '@styles/helper'
import Cards from '@molecules/cards'
import { parseMarginOrPadding } from '@styles/helper'

const ContributorPageLayout = styled(Layout)`
  main {
    max-width: 962px;
  }
  ${HeaderContainer} {
    margin-bottom: 0;
  }
`

const BackgroundImage = styled(Image)`
  position: absolute;
  top: -80px;
  max-width: 1400px;
  margin: auto;
  left: 0;
  right: 0;
  ${media.desktop_medium`
      top:  0;
  `}
`

const TitleBlock = styled.div`
  position: relative;
  overflow: hidden;
  width: 100vw;
  background: ${(props) => props.theme.palette.background[300]};
  height: 10rem;
  transform: translateX(-1rem);
  ${media.desktop_small_up`
      transform: translateX(calc((((100vw - 947px) / 2) * -1) - 1rem));
  `}
`

const ContributorDescription = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledIcon = styled.div`
  margin-top: -5rem;
  width: 150px;
  height: 150px;
  img {
    width: 100%;
    height: 100%;
  }
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  background-color: #d7dcea;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const StyledBio = styled.div`
  margin-top: 1rem;
  p {
    text-align: center;
    font-weight: 600;
  }
`

const ContributorStats = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-flow: row wrap;
  gap: 24px;
  justify-content: center;
`

const StyledSocialRow = styled.div`
  display: flex;
  height: 100%;
  min-height: 56px;
  flex-flow: row nowrap;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`

const AskMeAbout = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
`

const ServiceContainer = styled.div`
  margin: ${(props) => parseMarginOrPadding(props.margin, '0')};
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 1rem;
`

const ContributedMetrics = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
`

const ContributorPage = ({ data, location }) => {
  const contributor = data.contributor
  const description = `Contributor page for ${contributor.name}`
  const contImage = contributor.image
  const contMetrics = data.metrics.nodes
  const twitter = contributor.twitter
  const linkedin = contributor.linkedin

  const totalViews = data.allPageViewGa4.edges.reduce((acc, next) => {
    return acc + Number(next.node.pageViewCount)
  }, 0)

  // We should reconsider using the categories for the "Ask me about section"
  let allCategories = []
  let tags = []
  let allServiceNames = []
  let allServices = []
  contMetrics.forEach((metric) => {
    const categories = metric?.category
    if (categories) {
      categories.forEach((category) => {
        if (!allCategories.includes(category.name)) {
          allCategories.push(category.name)
          tags.push({
            name: category.name,
            link: category.slug
          })
        }
      })
    }
    const services = null //TODO: SERVICES
    if (services) {
      services.forEach((service) => {
        const obj = service.relationships.field_mt_service_reference
        if (!allServiceNames.includes(obj.name)) {
          allServiceNames.push(obj.name)
          allServices.push({
            name: obj.name,
            path: obj.path.alias
          })
        }
      })
    }
  }, [])
  return (
    <ContributorPageLayout>
      <SEO
        title={`Contributor | ${contributor.name}`}
        location={location}
        description={description}
      />
      <TitleBlock>
        <BackgroundImage src={background} alt="background image" />
      </TitleBlock>
      <Layout.Column center>
        <ContributorDescription>
          <StyledIcon>
            {contImage?.cdn ? (
              <Image file={contImage} alt={'contributor profile picture'} />
            ) : (
              <Icon name={'user'} size={5} />
            )}
          </StyledIcon>
          <Heading h3 as={'h2'} margin={'T1'}>
            {contributor.name}
          </Heading>
          {contributor.subtitle && (
            <Text as={'span'} margin={'T0.1'} size={0.85} color={'#485066'}>
              {contributor.subtitle}
            </Text>
          )}
          {contributor.bio && (
            <StyledBio>
              <SourceHTML html={contributor.bio} title={'test'} />
            </StyledBio>
          )}
        </ContributorDescription>
        <ContributorStats>
          {contMetrics && contMetrics.length > 0 && (
            <Card width={'294px'} list>
              <Card.Body>
                <Text color={500} bold size={2.5} center>
                  {contMetrics.length}
                </Text>
              </Card.Body>
              <Text color={'#000000'} center bold size={1.1}>
                Metrics contributed
              </Text>
            </Card>
          )}

          <Card width={'294px'} list>
            <Card.Body>
              <Text color={500} bold size={2.5} center>
                {new Intl.NumberFormat('en-US').format(totalViews)}
              </Text>
            </Card.Body>
            <Text color={'#000000'} center bold size={1.1}>
              People viewed my metrics
            </Text>
          </Card>

          {(twitter || linkedin) && (
            <Card width={'294px'} list>
              <Card.Body>
                <StyledSocialRow>
                  {twitter && (
                    <Social.Twitter
                      width={42}
                      height={42}
                      margin={0}
                      href={twitter}
                    />
                  )}
                  {linkedin && (
                    <Social.LinkedIn
                      width={42}
                      height={42}
                      margin={0}
                      href={linkedin}
                    />
                  )}
                </StyledSocialRow>
              </Card.Body>
            </Card>
          )}
        </ContributorStats>
        {(tags || allServices) && contMetrics && (
          <AskMeAbout>
            <Heading h3 as={'h2'} margin={'T1.5'}>
              I&#39;m an expert in
            </Heading>
            {tags && (
              <div style={{ marginTop: '1.5rem' }}>
                <Tags wrap={'left'} tags={tags} size={0.9} outlined />
              </div>
            )}
            {allServices && (
              <ServiceContainer margin={'T2'}>
                {allServices.map((service, i) => {
                  return (
                    <Link key={`service-${i + 1}`} to={service.path}>
                      <ServiceLogo logo={service.name} sm bg={'white'} />
                    </Link>
                  )
                })}
              </ServiceContainer>
            )}
          </AskMeAbout>
        )}
        {contMetrics && (
          <ContributedMetrics>
            <Heading h3 as={'h2'}>
              Contributed Metrics
            </Heading>
            <Layout.Grid fixed={294} gap={1.5} margin="T2">
              {contMetrics.slice(0, 6).map((node, i) => {
                const hasInstant = false //TODO: has instant metric template
                const hasBenchmark = node.benchmark ? true : false
                const hasExpert = isExpertContributed([contributor])
                const hasIcon = hasInstant || hasBenchmark || hasExpert
                return (
                  <Cards.Metric
                    key={`${node.name}-${i}`}
                    header={node.name}
                    auto="true"
                    hover="true"
                    link={node.slug}
                    footer={
                      <Layout.Row style={{ margin: '0 -0.25rem' }}>
                        {hasInstant && <Icon card name={'metric'} />}
                        {hasBenchmark && <Icon card name={'goal'} />}
                        {hasExpert && <Icon card name={'user'} />}
                      </Layout.Row>
                    }
                  >
                    <Text
                      lines={
                        (hasIcon ? 4 : 5) - Math.floor(node.name.length / 24)
                      }
                    >
                      {node.description.trim()}
                    </Text>
                  </Cards.Metric>
                )
              })}
            </Layout.Grid>
          </ContributedMetrics>
        )}
      </Layout.Column>
    </ContributorPageLayout>
  )
}

ContributorPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.string.isRequired
}

export default ContributorPage

export const pageQuery = graphql`
  query ContributorQuery($directus_id: String!, $pageViewRegex: String!) {
    metrics: allMetric(
      filter: {
        status: { eq: "published" }
        contributor: { elemMatch: { directus_id: { eq: $directus_id } } }
      }
    ) {
      nodes {
        directus_id
        slug
        name
        description
        benchmark
        category {
          name
          slug
        }
      }
    }
    site {
      siteMetadata {
        links {
          startTrial
        }
      }
    }
    allPageViewGa4(filter: { path: { regex: $pageViewRegex } }) {
      edges {
        node {
          pageViewCount
        }
      }
    }
    contributor: contributor(directus_id: { eq: $directus_id }) {
      name
      subtitle
      status
      twitter
      website
      linkedin
      image {
        cdn
        placeholder
      }
    }
  }
`
